<template>

  <v-container >
    <row-header :msg="msgRowHeader" action="UserNew"></row-header>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
              item-key="name"
              class="elevation-1"
              loading
              loading-text="Loading... Please wait"
              v-show="loading"
          ></v-data-table>
          <v-data-table
              dense
              :headers="headers"
              :items="users"
              :footer-props="rowsPerPageItems"
              :items-per-page="pagination"
              item-key="email"
              :search="search"
              v-show="!loading"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon
                  small
                  class="mr-2 green--text"
                  @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <dialog-confirm></dialog-confirm>
  </v-container>

</template>

<script>
import RowHeader from "@/components/content/RowHeader";
import DialogConfirm from "@/components/content/DialogConfirm";

export default {
  name: 'UserLocketList',
  components: {
    RowHeader,
    DialogConfirm,
  },
  data: () => ({
    search: null,
    rowsPerPageItems: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000]
    },
    pagination: 20,
    loading: true,
    msgRowHeader: {newUser: 'Create New Locket', newMsg: 'Locket\'s List', actionName: 'UserNewLocket'},
    users: [],
    headers: [
      { text: 'No', align: 'end', value: 'no'},
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Phone Number', value: 'phone_no' },
      { text: 'Role', value: 'role' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Action', value: 'actions', sortable: false, },
    ],
    userSelected: {},
  }),
  async mounted() {
    console.log('view page admin UserLocketList');
    this.loading = true;
    if (this.$store.getters.isLoggedIn) {
      this.users = await this.getUserData();
    }
    this.loading = false;
  },
  methods : {
    async getUserData() {
      try{
        this.$store.getters.currentToken.headers.KeyDesk = this.$store.getters.currentUser.user.desk_login;
        let response = await this.$http.get(this.$baseUrl + 'users?role_id=3&view=agent', this.$store.getters.currentToken)
        response.data.users.forEach((val, i) => {
          val.no = i+1;
          val.created_at = this.$moment(val.created_at).format('YYYY-MM-DD');
          val.name = val.name.length > 15 ? val.name.substring(0, 15)+'...' : val.name;
          val.email = val.email.length > 17 ? val.email.substring(0, 15)+'...' : val.email;
        })
        return response.data.users;
      }catch(err){
        console.log(err);
        alert('Error from server');
      }
    },
    editItem (item) {
      this.userSelected = item;
      this.$router.push({name: 'UserEdit', params: item})
    }
  }
}
</script>

<style scoped>

</style>
